<template>
  <div class="toilList">
    <div class="toilList_top">

      <div class="toilSwipe">
        <img src="@/assets/images/toilet/list_swipe.png" alt=""/>
      </div>

      <!--      <div class="line"></div>-->

      <div class="status">
        <div class="btn-status" style="border: 0.02rem solid #42b983; ">
          <div class="status-name" style="background: #42b983">畅通</div>
          <div class="status-time"><10分钟</div>
        </div>
        <div class="btn-status" style="border: 0.02rem solid #DDB37B; ">
          <div class="status-name" style="background: #DDB37B">忙碌</div>
          <div class="status-time">10-20分钟</div>
        </div>
        <div class="btn-status" style="border: 0.02rem solid #DC5740">
          <div class="status-name" style="background: #DC5740">拥堵</div>
          <div class="status-time">>20分钟</div>
        </div>
        <div class="btn-status" style="border: 0.02rem solid #C4C4C4;">
          <div class="status-name" style="background: #C4C4C4">关闭</div>
          <div class="status-time">当前关闭中</div>
        </div>
      </div>

    </div>

    <template v-if="toiletList.length">

      <div class="list_border">


        <div class="list" v-for="(item, index) in toiletList" :key="index" @click="">

          <div class="right_top green" v-if="item.level==1">畅通</div>
          <div class="right_top busy" v-if="item.level==2">忙碌</div>
          <div class="right_top red" v-if="item.level==3">拥堵</div>
          <div class="right_top close" v-if="item.level==4">关闭</div>

          <div class="title">{{ item.name }}</div>
          <div class="second">
            <div class="second_address">地址: {{ item.address }}</div>
            <div class="second_distance">{{ item.distance }}km</div>
          </div>

          <div class="third">
            <div class="third_left">
              <div class="third_left_row">
                <div class="row_img_1">
                  <!--                  <img src="@/assets/images/toilet/nan.png" alt=""/>-->
                </div>
                <div class="row_text"> 男厕: {{ item.nanNum }} ，空余: {{ item.nanSpace }}</div>
              </div>
              <div class="third_left_row">
                <div class="row_img_2">
                  <!--                  <img src="@/assets/images/toilet/nan.png" alt=""/>-->
                </div>
                <div class="row_text"> 女厕: {{ item.nvNum }} ，空余: {{ item.nvSpace }}</div>
              </div>
            </div>
            <div class="third_right">

              <div class="btn" @click="showList(item.name,item.lng,item.lat)">到这去</div>
            </div>
          </div>


        </div>
      </div>

    </template>
    <van-popup v-model="show" position="bottom" safe-area-inset-bottom :style="{ height: '20%' }">
      <div class="Applist">
        <div @click="selectApp(1)">高德地图</div>
        <div class="line"></div>
        <div @click="selectApp(2)">百度地图</div>
        <div class="line"></div>
        <div @click="selectApp(3)">腾讯地图</div>
      </div>
    </van-popup>
  </div>


</template>
<script>
import {getToiletList} from "@/api/common";
import {Toast} from "vant";
import wx from 'weixin-js-sdk';
import axios from "axios";

const geolocation = new qq.maps.Geolocation();
export default {
  name: "toilet_list",
  data() {
    return {
      toiletLocation: {
        lng: null,
        lat: null,
        name: null,
      },
      show: false,
      toiletList: [],
      //判断是否是微信环境
      isWeixin: false
    }
  },

  mounted() {
    this.initWxconfig()
  },
  methods: {
    initWxconfig() {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://wxshare.bcitymap.com/share.php', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      xhr.send('url=' + window.location.href + '&path=' + window.location.host);
      var that=this



      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          let data = null
          console.log(xhr.response.appId)
          console.log(xhr.response)
          console.log()
          data = JSON.parse(xhr.response)

          wx.config({
            beta: true,
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: [
              'getLocation', 'openLocation'
            ],
          });

          Toast.loading({
            message: '获取位置信息中...',
            forbidClick: true,
            position: 'top',
            duration: 0,
          });

          wx.ready(function () {
            wx.getLocation({
              type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                var speed = res.speed; // 速度，以米/每秒计
                var accuracy = res.accuracy; // 位置精度

                let location = longitude + "," + latitude

                that.getToiletList(location);
              },
              fail: function (err) {
                alert("获取定位位置信息失败！")
              },
              cancel: function (res) {
                alert('用户拒绝授权获取地理位置');
              }
            });
          });
        }
      }
    },
    //打开导航
    showList(name, lng, lat) {
      //先清空
      this.toiletLocation.lng = null
      this.toiletLocation.lat = null
      this.toiletLocation.name = null
      //在赋值
      this.toiletLocation.lng = lng
      this.toiletLocation.lat = lat
      this.toiletLocation.name = name
      // this.show

      wx.openLocation({
              latitude: Number(lat), // 纬度，浮点数，范围为90 ~ -90
              longitude: Number(lng), // 经度，浮点数，范围为180 ~ -180。
              name: name, // 位置名
              address: '', // 地址详情说明
              scale: 16, // 地图缩放级别,整型值,范围从1~28。默认为最大
      })

      // if(this.isWeixin){
      //
      //     wx.openLocation({
      //       latitude: Number(lat), // 纬度，浮点数，范围为90 ~ -90
      //       longitude: Number(lng), // 经度，浮点数，范围为180 ~ -180。
      //       name: name, // 位置名
      //       address: '', // 地址详情说明
      //       scale: 16, // 地图缩放级别,整型值,范围从1~28。默认为最大
      //     })
      //
      // }else {
      //   this.show=true
      // }
    },

    // const xhr = new XMLHttpRequest();
    // xhr.open('POST', 'https://wxshare.bcitymap.com/share.php', true);
    // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    // xhr.setRequestHeader('X-Content-Type-Options', 'nosniff');
    // xhr.send('url=' + window.location.href + '&path=' + window.location.host);


    //判断是不是微信浏览器环境
    // isWx() {
    //   var ua = navigator.userAgent.toLowerCase();
    //   var isWeixin = ua.indexOf('micromessenger') != -1;
    //   // var docEl = document.documentElement
    //   // var winWidth = document.body.clientWidth || document.documentElement.clientWidth;
    //   // var winHeight = document.body.clientHeight || document.documentElement.clientHeight;
    //
    //
    //   if (isWeixin) {
    //     this.isWeixin = true
    //     this.initWxconfig()
    //     // alert("微信浏览器环境"+"高度"+winHeight+"宽度"+winWidth)
    //     // docEl.style.fontSize = '55px'
    //   } else {
    //     // this.getCurrentLocation()
    //     // alert("H5浏览器环境"+"高度"+winHeight+"宽度"+winWidth)
    //     // docEl.style.fontSize = '50px'
    //   }
    // },

    // getCurrentLocation() {
    //   Toast.loading({
    //     message: '获取位置信息中...',
    //     forbidClick: true,
    //     position: 'top',
    //     duration: 0,
    //   });
    //   var options = {timeout: 9000};
    //   geolocation.getLocation(this.showPosition, this.showErr, options);
    // },
    //
    // showPosition(position) {
    //   Toast.clear()
    //   Toast.loading({
    //     message: '加载中...',
    //     forbidClick: true,
    //     position: 'top',
    //     duration: 0,
    //   });
    //   // alert(JSON.stringify(position,null,4))
    //   let location = position.lng + "," + position.lat
    //   this.getToiletList(location)
    // },
    //
    // showErr() {
    //   Toast.clear();
    //   Toast({
    //     message: '获取位置信息失败',
    //     position: 'top',
    //   });
      // alert(JSON.stringify(position,null,4))
      // let location=120.322121+","+30.212321
      // this.getToiletList(location)


    getToiletList(location) {
        Toast.clear()
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          position: 'top',
          duration: 0,
        });
          var data = {
            size: "3",
            location: location,
         }
      getToiletList(data).then(res => {
        // console.log(JSON.stringify(res.data))
        this.toiletList = res.data
        Toast.clear();
      })
    },

    getSys() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        //ios环境
        return 'IOS'
      } else if (navigator.userAgent.match(/android/i)) {
        //安卓环境
        return 'Android'
      } else {
        return 'H5'
      }
    },

    /**
     * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换 / 即谷歌、高德 转 百度
     * @param { Number } lng:需要转换的经纬
     * @param { Number } lat:需要转换的纬度
     * @return { Array } result: 转换后的经纬度数组
     */
    gcj02tobd09(lng, lat) {
      var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * Math.PI)
      var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * Math.PI)
      var bd_lng = z * Math.cos(theta) + 0.0065
      var bd_lat = z * Math.sin(theta) + 0.006
      return [bd_lng, bd_lat]
    },

    selectApp(val) {
      Toast({
        message: '正在打开导航...',
        position: 'top',
      });
      //跳转高德地图
      if (val === 1) {
        const {lng, lat, name} = this.toiletLocation;
        // // 获取到用户定位信息，走导航模式；未获取到用户定位信息，走单点标注模式
        // if (usrLat && usrLon) {
        //   window.location.href = `https://uri.amap.com/navigation?from=${usrLon},${usrLat},我的位置&to=${lonEnd},${latEnd},${destination}&mode=driving&policy=1&src=mypage&coordinate=gaode&callnative=1`;
        // } else {
        const sys = this.getSys();

        if (sys === 'Android') {
          //判断系统是安卓还是苹果
          window.location.href = `amapuri://route/plan/?sourceApplication=xt&dlat=${lat}&dlon=${lng}&dname=${name}&dev=0&t=0`;
        } else {
          window.location.href = `iosamap://path?sourceApplication=xt&dlat=${lat}&dlon=${lng}&dname=${name}&dev=0&t=0`;
        }

        window.setTimeout(() => {
          window.location.href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${name}&src=mypage&coordinate=gaode&callnative=1`
        }, 1000);

      }
      //跳转百度地图
      if (val === 2) {
        const {lng, lat, name} = this.toiletLocation;

        const res = this.gcj02tobd09(lng, lat)
        console.log("转换前" + lng + "," + lat)
        console.log("转换后" + res[0] + "," + res[1])

        const sys = this.getSys();
        // 获取到用户定位信息，走导航模式；未获取到用户定位信息，走单点标注模式
        const urlCollect = {};

        const uri = `marker?location=${res[1]},${res[0]}&title=${name}&content=${name}`;
        urlCollect.H5 = `https://api.map.baidu.com/${uri}&output=html&src=webapp.baidu.openAPIdemo`;
        urlCollect.Android = `bdapp://map/${uri}&src=andr.baidu.openAPIdemo`;
        urlCollect.IOS = `baidumap://map/${uri}&src=ios.baidu.openAPIdemo`;

        window.location.href = urlCollect[sys];
        if (sys === 'H5') return;
        // 未安装app的补偿机制
        window.setTimeout(() => {
          window.location.href = urlCollect.H5;
        }, 1000);
      }

      //跳转腾讯地图
      if (val === 3) {

        const {lng, lat, name} = this.toiletLocation;
        // const { usrLat, usrLon } = this.getGPSInfo();
        const TMAP_KEY = 'WYJBZ-RTSKZ-N23XV-TO4N2-T2VWJ-D2B3S'
        let mobileUrl = '';
        let h5Url = '';

        mobileUrl = `qqmap://map/marker?marker=coord:${lat},${lng};title:${name};addr:${name}&referer=${TMAP_KEY}`;
        h5Url = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};title:${name};addr:${name}&referer=${TMAP_KEY}`;

        window.location.href = mobileUrl;
        window.setTimeout(() => {
          window.location.href = h5Url;
        }, 1000);
      }
    }
  },
}
</script>
<style scoped lang="less">

.Applist {
  width: 100%;
  height: 2rem;
  //background: #42b983;
  margin: auto;
}

.Applist div {
  text-align: center;
  margin-top: 0.25rem;;
  font-size: 0.35rem;
}

.toilList {
  min-height: 120vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  //background-color: #1989fa;
  background-image: url('~@/assets/images/toilet/list_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.toilList_top {
  width: 6.9rem;
  min-height: 3.08rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  margin: 0.4rem auto 0;
}

.toilSwipe {
  width: 6.5rem;
  height: 1.45rem;
  border-radius: 0.1rem;
  margin: 0.2rem auto 0.2rem;
}

.toilSwipe img {
  width: 6.5rem;
  height: 1.45rem;
  border-radius: 0.1rem;
  margin-top: 0.2rem;
  // margin:  0.2rem auto 0.2rem
}

.line {
  width: 100%;
  height: 0.02rem;
  background: #000000;
  opacity: 0.05;
}

.toilList_con {
  width: 100%;
  margin-top: 0.3rem;
}

.load {
  width: 3.5rem;
  //height: 3.5rem;
  margin: auto;
  background: #fff;
}

.list_border {
  //border: 1px solid red;
  width: 6.9rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
}


.list {
  width: 6.3rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  margin-top: 0.4rem;
  //margin: 0.4rem auto 0;
  ////overflow: hidden;
  position: relative;
  height: 2.8rem;
  padding: 0.3rem;

}

.title {
  color: black;
  font-size: 0.3rem;
  width: 6.3rem;
  //background: #42b983;
  //margin-top: 0.2rem;
  //margin-left: 0.1rem;
}

.second {
  width: 6.6rem;
  //background: yellow;
  font-size: 0.24rem;
  font-weight: 400;
  color: #333333;
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.second_address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.third {
  width: 6.6rem;
  height: 1.7rem;
  //background: rosybrown;
  margin-top: 0.2rem;
  display: flex;
}

.third_left {
  width: 5rem;
  height: 100%;
  //background: #42b983;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.third_left_row {
  width: 100%;
  height: 0.3rem;
  //background: blue;
  display: flex;

}

.row_img_1 {
  width: 0.3rem;
  height: 0.3rem;
  //background:yellow;
  background-image: url('@/assets/images/toilet/nan.png');
  background-size: 100% 100%;
}

.row_img_2 {
  width: 0.3rem;
  height: 0.3rem;
  //background:yellow;
  background-image: url('@/assets/images/toilet/nv.png');
  background-size: 100% 100%;
}

//.row_img img{
//  width: 0.25rem;
//  height: 0.25rem;
//  //background: #6AB0E6;
//  //border-radius: 0.05rem;
//}
.row_text {
  font-size: 0.24rem;
  // font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-left: 0.25rem;
}


.third_right {
  width: 1.8rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background:rebeccapurple;
}

.right_top {
  width: 1rem;
  height: 0.42rem;

  position: absolute;
  top: 0;
  right: 0;

  font-size: 0.24rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.42rem;
  text-align: center;
  border-radius: 0 0.2rem 0 0.2rem;
  background: #42b983;
}

.green {
  background: #42b983;
}

.red {
  background: #DC5740;
}

.busy {
  background: #DDB37B;
}

.close {
  background: #C4C4C4;
}

.btn {
  width: 1.1rem;
  height: 0.48rem;
  border: 0.02rem solid #DDB37B;
  border-radius: 0.24rem;
  font-size: 0.24rem;
  // font-family: Microsoft YaHei;
  font-weight: 400;
  color: #DDB37B;
  //float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  height: 0.75rem;
  width: 6.5rem;
  //background: #42b983;
  margin: 0.4rem auto 0;
  display: flex;
  justify-content: space-between;
}

.btn-status {
  width: 1.47rem;
  height: 0.75rem;
  //background: wheat;
  border-radius: 0.1rem;
  transition: .2s linear box-shadow
}

.status-name {
  width: 100%;
  height: 0.46rem;
  //background: #68CDAF;
  border-radius: 0.03rem 0.03rem 0.1rem 0.1rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.46rem;
}

.status-time {
  width: 100%;
  height: 0.25rem;
  font-size: 0.18rem;
  font-weight: 400;
  //color: #68CDAF;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
