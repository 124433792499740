import request from '@/utils/request'
// 查询常用意见列表
export function listComment(query) {
    return request({
        url: '/toilet/test',
        method: 'post',
        // params: query
    })
}


// 获得西塘厕所列表
export function getToiletList(data) {
    return request({
        url: '/toilet/mobileList/',
        method: 'post',
        data:data
    })
}
// 新增常用意见
export function addComment(data) {
    return request({
        url: '/flow/comment',
        method: 'post',
        data: data
    })
}
// 修改常用意见
export function updateComment(data) {
    return request({
        url: '/flow/comment',
        method: 'put',
        data: data
    })
}
// 删除常用意见
export function delComment(id) {
    return request({
        url: '/flow/comment/' + id,
        method: 'delete'
    })
}
